import { portalConstants } from '../constant';
export const portalAction = {
    setSettings,
    portalIsLoading,
    setIsSubmitting,
};

function setSettings(settings) {
    return (dispatch) => {
        dispatch({ type: portalConstants.SET_PORTAL_SETTINGS, payload: settings });
    };
}

function portalIsLoading(flag) {
    return (dispatch) => {
        dispatch({ type: portalConstants.PORTAL_LOADING, payload: { isLoading: flag } });
    };
}

function setIsSubmitting(flag) {
    return (dispatch) => {
        dispatch({ type: portalConstants.SET_IS_SUBMITTING, payload: { isSubmitting: flag } });
    };
}
